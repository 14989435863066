<template>
  <div class="top-banner">
    <div class="left-content">
      <img src="@/assets/logo.png" alt="Logo" class="logo" />
      <span class="title" @click="goHome">锂离子电池行业专利数据库</span>
    </div>
    <div class="right-content">
      <div class="icon-group">
        <div  v-if="isAdmin" class="icon-item"  @click="goManagement">
          <el-icon>
            <Setting />
          </el-icon>
          <span class="admin-button">后台</span>
        </div>
        <div class="icon-item" @click="handleLogin">
          <el-icon>
            <User />
          </el-icon>
          <span class="login-text">退出登录</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { User } from '@element-plus/icons-vue'
import { Setting } from '@element-plus/icons-vue'
console.log('isAdmin',localStorage.getItem('isAdmin'));
const isAdmin = localStorage.getItem('isAdmin') == '1';
const goHome =() => {
  window.location.href = '/h'
}
const goManagement =() => {
  window.location.href = '/h/management'
}

const handleLogin = () => {
  localStorage.removeItem('isAdmin');
  localStorage.removeItem('userToken');  
  // router.push('/login');  
  // window.location.href = 'login'
  window.location.href = '/h/login';
}
// 获取
// const is_admin = localStorage.getItem('userToken').is_admin;
console.log('is_admin::',localStorage.getItem('userToken'));
</script>

<style scoped>
.top-banner {
  background-color: #00245a;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.left-content, .right-content {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.title {
  font-size: 20px;
}
.title:hover {
  cursor: pointer;  
}

.right-content {
  width: 170px;
  cursor: pointer;
  margin-left: auto; /* 将 right-content 推到右侧 */
  background-color: #00245a;
}

.icon-group {
  display: flex;
  align-items: center;
  /* margin-right: 10px; */
}

.icon-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.icon-item:last-child {
  margin-right: 0;
}

.login-text, .admin-button {
  /* margin-left: 5px; */
  font-size: 16px;
}
</style>
