<template>
  <div class="root-page">

    <TopBanner/>
    <div class="common-layout">
      <el-container class="container">
          <el-aside class="el-aside box_left" style="background-color: white;">
              <!-- 左侧菜单 -->
              <NavMenu  @inventionPointAdded="handleInventionPointAdded" @selectionChangeSQR="selectionChangeSQR" />            
          </el-aside>
          <el-main class="el-main box_right">
              <!-- 右侧内容显示区域 -->
                <FilterShow/>
              <!-- 搜索结果 -->
              <SearchResults/>
          </el-main>
      </el-container>
    </div>

    
  </div>

</template>

<script>
import NavMenu from './NavMenu.vue';
import FilterShow from  './FilterShow.vue';
import TopBanner from  './TopBanner.vue';
import SearchResults from  './SearchResults.vue';
 

export default {
  components: {
    NavMenu,
    FilterShow,
    TopBanner,
    SearchResults,
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
};
</script>

<style>

.root-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #f2f2f2;
  /* padding-bottom: 20px; */
}
.sxfl_title {
  padding-left: 1%;
  text-align: left;
}

.sxfl_item {
  text-align: left;
}
.common-layout{
  width: 100%;
  margin-top: 30px; /* 确保内容不会被顶部的banner遮挡 */
}
.el-main{
  margin-left: 10px;
  padding: 0;
}
.el-aside{
  height: 100%
  
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

.box_left{
  cursor: pointer;
  width: 280px;
  position: fixed;
  height: 100%; 
  overflow-y: auto;
}

   
.box_left::-webkit-scrollbar {
  display: none; 
}

.box_right{
  position: relative;
  left:0px;
  margin-left:290px;
}

</style>
