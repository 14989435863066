<template>
    <div>
        <!-- from表单 文件上传 -->
        <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="所属库" v-show="personnelShow">
                <el-select
                    v-model="belongingPerson"
                    
                    placeholder="请选择所属库"
                    style="width: 240px"
                    >
                    <el-option
                        v-for="item in parentMsg"
                        :key="item.uid"
                        :label="item.uname"
                        :value="item.uid"
                    />
                    </el-select>
            </el-form-item>
            <el-form-item>
                <el-upload
                    class="upload-demo"
                    :auto-upload="false"
                    :show-file-list="false"
                    :onchange="importClick"
                    :accept="personnelShow?'.xlsx,.xls':'.pdf'"
                >
                    <template #trigger>
                    <el-button type="primary">选择文件</el-button>
                    </template>
                </el-upload>
            </el-form-item>
        </el-form>

        <!-- 表格显示上传文件 -->
        <el-scrollbar >
        <el-table v-show="personnelShow"  :data="excelList"  style="width: 100%">
            <el-table-column prop="file" label="文件名"></el-table-column>
            <el-table-column prop="create_time" label="上传时间" ></el-table-column>
            <el-table-column prop="done_time" label="完成时间" ></el-table-column>
            <el-table-column prop="status" label="状态" ></el-table-column>
            <el-table-column prop="uname" label="所属库" ></el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click="download(scope)">下载</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-table v-show="!personnelShow"  :data="pdfList"  style="width: 100%">
            <el-table-column prop="file" label="文件名"></el-table-column>
            <el-table-column prop="create_time" label="上传时间" ></el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click="download(scope)">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-scrollbar>
        <el-pagination
            :page-size="pageSize"
            :page-sizes="[5, 10, 20, 50]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="personnelShow?excelTotal:pdfTotal"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            />
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { ElMessage } from 'element-plus';

export default{
    data(){
        return{
            excelList_test:[
                {
                    file: "NEC测试数据 - 副本_plmoavg.xlsx",
                    create_time: "2024-08-09T02:28:23.261",
                    done_time: "2024-08-09T02:28:23.688",
                    uname: "管理员,齐海朋1",
                    status: 2,
                    url: "/media/upload/2024/08/09/NEC测试数据 - 副本_plmoavg.xlsx"
                }
            ],
            belongingPerson:[],
            currentPage:1,
            pageSize:10
        }
    },
    methods: {
        importClick(event) {
            const file = event.target.files[0]; // 获取文件对象
            console.log('所属库：',this.belongingPerson);
            let type='';
            if(this.personnelShow){
                type= 'excel';
                if (!file || this.belongingPerson.length == 0) {
                    ElMessage({
                        message: '请选择文件和所属库',
                        type: 'warning',
                        plain: true,
                    });
                    return;
                }
            }else{
                type='pdf';
                if (!file) {
                    ElMessage({
                        message: '请选择文件',
                        type: 'warning',
                        plain: true,
                    });
                    return;
                }
            }
            
            // const belongUid = this.belongingPerson.join(',');
            const belongUid = this.belongingPerson;
            const formData = new FormData();
            formData.append('file', file);
            if(this.personnelShow){
                formData.append('belong_uid', belongUid);
            }
            
            const post_url= type=='excel' ? '/api/excel2es?type='+type : '/api/pdf2es?type='+type;
            // 可以将所属库信息以查询参数的形式附加在 URL 后
            axios.post(post_url, formData, {
                // params: {
                //     belong_uid: belongUid
                // },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                if (res.data.meta.status === 200) {
                    ElMessage({
                        message: '解析成功',
                        type: 'success',
                        plain: true,
                    });
                    this.handleCurrentChange(this.currentPage);// 刷新页面
                    // location.reload();
                } else {
                    ElMessage({
                        message: '失败,请检查文件后重试',
                        type: 'error',
                        plain: true,
                    });
                }
            }).catch(err => {
                console.error('请求失败:', err);
            });
            // 不等待接口返回直接提示，数据量大，处理慢
            ElMessage({
                        message: '导入成功，请等待解析',
                        type: 'success',
                        plain: true,
                    });
            setTimeout(() => {  
                this.handleCurrentChange(this.currentPage);  
            }, 1000); // 1000毫秒后执行，刷新页面
            setTimeout(() => {  
                this.handleCurrentChange(this.currentPage);  
            }, 3000); // 3000毫秒后执行，刷新页面
            // this.handleCurrentChange(this.currentPage)
        },
        download(e) {
            const link = document.createElement('a');
            link.href = e.row.url;
            link.setAttribute('download', '');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async handleSizeChange(e){
            // 每页展示多少条
            this.pageSize=e;
            let type= this.personnelShow ? 'excel' : 'pdf';
            try {
                const response = await axios.get('/api/get_excel_list?page='+this.currentPage+'&size='+this.pageSize+'&type='+type) // 向后端发送GET请求获取数据
                if(this.personnelShow){
                    this.excelList=response.data.data
                }else{
                    this.pdfList=response.data.data
                }
            } catch (error) {
                console.error('请求失败:', error)
            }
        },
        async handleCurrentChange(e){
            // 点击页面
            let type= this.personnelShow ? 'excel' : 'pdf';
            this.currentPage=e;
            try {
                const response = await axios.get('/api/get_excel_list?page='+this.currentPage+'&size='+this.pageSize+'&type='+type) // 向后端发送GET请求获取数据
                if(this.personnelShow){
                    this.excelList=response.data.data
                }else{
                    this.pdfList=response.data.data
                }
            } catch (error) {
                console.error('请求失败:', error)
            }
        }
    },
    setup(){
        let excelList=ref([]);
        let pdfList=ref([]);
        let excelTotal=ref();
        let pdfTotal=ref();
        onMounted(async() => {
        try {
        const pa=1
        const si=10
          const response = await axios.get('/api/get_excel_list?page='+pa+'&size='+si+'&type=excel') // 向后端发送GET请求获取数据
          excelList.value=response.data.data;
          excelTotal.value=response.data.meta.total_count;
        } catch (error) {
          console.error('请求失败:', error)
        }
        try {
        const pa=1
        const si=10
          const response = await axios.get('/api/get_excel_list?page='+pa+'&size='+si+'&type=pdf') // 向后端发送GET请求获取数据
          pdfList.value=response.data.data;
          pdfTotal.value=response.data.meta.total_count;
        } catch (error) {
          console.error('请求失败:', error)
        }
      })
      return{
        excelList,
        pdfList,
        excelTotal,
        pdfTotal
      }
    },
    props:['parentMsg','personnelShow']
}
</script>

<style>
.el-pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}
</style>