/*
*****************************************************在这里配置路由*****************************************************
*/ 
import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/LoginVue.vue'; // 登录页面
import MainPage from '../components/MainPage.vue'; // 列表页面
import NavMenu from '../components/NavMenu.vue'; // 列表页面
import IconPat from '../components/IconPat.vue'; // 列表页面
import Management from '../components/ManagementPage.vue';//后台管理


const routes = [
  {
    path: '/h',
    name: 'Main',
    component: MainPage,
    meta: { requiresAuth: true }  //需要登录才能访问
  },
  
  {
    path: '/h/nav',
    name: 'NavMenu',
    component: NavMenu
  },  
  {
    path: '/h/login',
    name: 'Login',
    component: Login
  },  
  {
    path: '/h/IconPat',
    name: 'IconPat',
    component: IconPat
  },
  {
    path:'/h/management',
    name:'Management',
    component:Management,
    meta: { requiresAuth: true }
  }
]; 

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoginRequired = to.matched.some(record => record.meta.requiresAuth)
  const isUserLoggedIn = localStorage.getItem('userToken') // 假设用userToken作为登录标识
 
  if (isLoginRequired && !isUserLoggedIn) {
    next('/h/login') // 未登录则重定向到登录页面
  } else {
    next() // 已登录，或者不需要登录的页面，正常导航
  }
})
export default router;
