<template>
    <div class="search-results">

      <div class="header"   >
        <el-row style="width: 100%;">
            <el-col :span="5">
                <font>共<span class="totalll">{{totalItems}}</span>件专利(共{{allItems}}条)</font>
                <!-- <font>共{{totalItems}}件专利(共{{allItems}}条)</font> -->
            </el-col>
            <el-col :span="6"  >
                <el-dropdown @command="handleSort" style="margin-top:2%;">
                    <span>
                        <svg t="1727166705938" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10505" width="16" height="16"><path d="M420.559974 72.98601l-54.855 0 0 774.336c-52.455014-69.163008-121.619046-123.762995-201.120051-157.052006l0 61.968c85.838029 41.401958 156.537958 111.337984 201.120051 198.221005l0 0.208 54.855 0 0-13.047c0.005018-0.00297 0.010035-0.005018 0.01495-0.007987-0.005018-0.010035-0.010035-0.019968-0.01495-0.030003L420.559974 72.986zM658.264986 73.385984l0-0.4L603.41 72.985984l0 877.68 54.855 0L658.265 176.524c52.457984 69.178982 121.632051 123.790029 201.149952 157.078016l0-61.961C773.560013 230.238003 702.853018 160.287027 658.264986 73.385984z" p-id="10506"></path></svg>
                        <span>排序：{{ sortOptions[sortOrder] }} <i class="el-icon-arrow-down el-icon--right"></i></span>
                    </span>
                    <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-for="(label, key) in sortOptions" :key="key" :command="key">{{ label }}</el-dropdown-item>
                    </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-col>
            <el-col :span="13" style="text-align: right;">
                
                <el-checkbox-button v-model="selectAll" @change="toggleSelectAll">全选</el-checkbox-button>
                <el-checkbox-button v-model="deselectAll" @change="toggleDeselectAll">取消全选</el-checkbox-button>
                <!-- <span  color="#626aef" plain @click="exportFile">导出</span> -->
                <el-button  style="margin-left: 20px;"  color="#00245a" plain :icon="Download" size="small" @click="exportFile">下载</el-button>
                <el-button class="floating-button" style="margin-left: 20px;"  color="#00245a"   :icon="Download" size="small" @click="exportFile">下载</el-button>
            </el-col>
           
        </el-row>
      </div>
      
      <div class="content">
        <div v-for="item in items" :key="item._source.shenqinghao" class="item-card">
            <el-row >
                <el-col :span="20" style="text-align: left;">
                    <h4>{{ item._source.mingcheng }}</h4>
                    <!-- <div>id: {{ item._source.shqningren }}</div> -->
                    <div><b>状态:</b> <span :class="item._source.dqflzt == '授权'? 'authorization':'expiration'" >{{ item._source.dqflzt }}</span></div>
                    <div><b>国家:</b> <span class="country">{{ item._source.gkgb }}</span></div>
                    <div><b>申请人: </b>{{ item._source.sqren }}</div>
                    <div><b>申请号:</b> <span class="shenqh">{{ item._source.sqh }} </span>&nbsp; <b>申请日期</b>: {{ item._source.sqr.split('T')[0] }}</div>
                    <div><b>公布号:</b> <span class="shenqh">{{ item._source.gkggh }}</span>  &nbsp; <b>公布日期</b>: {{ item._source.gkgr.split('T')[0] }}</div>
                    <div><b>发明点:</b> <span class="famingd">{{ item._source.fmd }}</span></div>
                    <div><b>技术效果:</b> {{ item._source.jsxg || '无' }}</div>
                </el-col>
                <el-col :span="3" style="text-align: right; padding-top: 1%">
                        <el-checkbox v-model="item._source.selected"   size="large" label="选择" ></el-checkbox>
                </el-col>
                <!-- <el-col :span="1" style=" padding-top: 10%">
                    <a href="{{ item._source.linke_iconpat }}" class="xiangqing">详情</a>
                </el-col> -->
                    
            </el-row>
        </div>
      </div>
      
      <div class="footer">
        <!-- <el-select v-model="itemsPerPage" placeholder="每页 X 条" @change="fetchData">
          <el-option v-for="option in itemsPerPageOptions" :key="option" :label="`${option} 条`" :value="option"></el-option>
        </el-select> -->

        <el-pagination
          :page-sizes="[10, 20, 50, 100]"
          @current-change="handlePageChange"
          @size-change="handleSizeChange"
          :current-page="currentPage"
          
          layout="sizes,total, prev, pager, next"
          :total="totalItems"
        ></el-pagination>
      </div>
    </div>
  </template>
  
  <script setup>
        import { useMainStore } from '../stores/useMainStore';
        import sharedMethods from '@/utils.js'; //引入公共方法
        import { ref, onMounted,watch } from 'vue';
        import {Download} from '@element-plus/icons-vue'
        // import { ref, onMounted, computed } from 'vue';
        import axios from 'axios';
        import { ElMessage } from 'element-plus';
        import { saveAs } from 'file-saver';  
        
        const store = useMainStore();
        // const itemsPerPageOptions = [10, 20, 30, 50];
        const sortOptions = {
            'shenqingriqi_desc': '申请日（最新→最旧）',
            'shenqingriqi_asc': '申请日（最旧→最新）',
            'gongburiqi_desc': '公开日（最新→最旧）',
            'gongburiqi_asc': '公开日（最旧→最新）',
        };
        
        // const itemsPerPage = ref(itemsPerPageOptions[0]);
        const sortOrder = ref('shenqingriqi_desc');
        const selectAll = ref(false);
        const deselectAll = ref(false);
        const currentPage = ref(1); // 当前页
        
        const totalItems = ref(0); // 搜到的数量
        const allItems = ref(0); // 数据库里面的总数量
        const items = ref([]); // 搜到的数据详情
        
        // const displayedItems = computed(() => {
        //     return items.value
        //     // const start = (currentPage.value - 1) * itemsPerPage.value;
        //     // const end = start + itemsPerPage.value;
        //     // return items.value.slice(start, end);
        // });
        // const zhuanli_count = () =>{
        //     axios.get('/api/zhuanli_count')
        //     .then(response => {
        //         allItems.value = response.data.data; 
        //     })
        //     .catch(error => {
        //         console.error('Error fetching sqrData:', error);
        //     });
        // }
        const zhuanli_count = async () => {  
            try {  
                const response = await axios.get('/api/zhuanli_count');  
                allItems.value = response.data.data;  
            } catch (error) {  
                console.error('Error fetching zhuanli_count:', error);  
            }  
        };
        const fetchData = async () => {
            try {
                const response = await sharedMethods.goSearch('search_zhuangli');
                items.value = response.data.data;
                totalItems.value = response.data.total_item;
                // allItems.value = response.data.all_item;
                // items.value = response.data.data.map(item => ({
                //     ...item,
                //     selected: false,
                // }));
            } catch (error) {
                ElMessage.error('数据获取失败~');
            }
        };
        
        const handleSort = async (command) => {
            sortOrder.value = command;
            console.log('排序',command);
            store.setsortType(command);
            try {
                const response = await sharedMethods.goSearch('search_zhuangli');
                items.value = response.data.data;
                totalItems.value = response.data.total_item;
                // allItems.value = response.data.all_item;
            } catch (error) {
                ElMessage.error('数据获取失败~');
            }
            console.log('点击排序');
        };
        
         
        
        const handlePageChange = async (page) => {
            currentPage.value = page;
            store.setnowPage(page);
            try {
                const response = await sharedMethods.goSearch('search_zhuangli');
                items.value = response.data.data;
                totalItems.value = response.data.total_item;
                // allItems.value = response.data.all_item;
            } catch (error) {
                ElMessage.error('数据获取失败~');
            }
            console.log('点击分页');
        };
        const handleSizeChange = async (size) => {
            
            store.setpageSize(size);
            try {
                const response = await sharedMethods.goSearch('search_zhuangli');
                items.value = response.data.data;
                totalItems.value = response.data.total_item;
                // allItems.value = response.data.all_item;
            } catch (error) {
                ElMessage.error('数据获取失败~');
            }
            console.log('点击分页');
        };
        
        const toggleSelectAll = () => {
            console.log('全选按钮');
            if (selectAll.value) {
                deselectAll.value = false;
                items.value.forEach(item => {
                item._source.selected = true;
                });
            }
        };

        const toggleDeselectAll = () => {
            if (deselectAll.value) {
                selectAll.value = false;
                items.value.forEach(item => {
                item._source.selected = false;
                });
            }
        };
        const  exportFile = async() => {
            console.log('点击导出');
            const zlh_list = []
            items.value.forEach(item => {
                if(item._source.selected){
                    zlh_list.push(item._source.sqh)
                }
            });
            console.log(zlh_list);
            await axios.post('/api/check_pdf_files_exist',{'zlh_list':zlh_list},{headers: {
                    'Content-Type': 'multipart/form-data'
                }})
            .then(response => {
                if(response.data.meta.status === 200){
                    ElMessage({
                                message: '处理成功，下载中',
                                type: 'success',
                                plain: true,
                            });
                    // 文件存在，执行下载
                    axios.post('/api/export_pdf',{'zlh_list':zlh_list},{headers: {
                            'Content-Type': 'multipart/form-data'
                        }})
                    .then(response => {
                        
                        fetch(response.data.data.url)  
                        .then(response => response.blob())  
                        .then(blob => saveAs(blob, response.data.data.zip_name)) // 你需要知道文件的正确名称或后缀  
                        console.log(response.data.data.url);
                    })
                    .catch(error => {
                        console.error('Error fetching sqrData:', error);
                    });
                }else{
                    ElMessage({
                        message: response.data.meta.msg,
                        type: 'error',
                        plain: true,
                        duration: 5000, // 设置显示时间为20秒（20000毫秒）
                        showClose: true, // 显示关闭按钮
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching sqrData:', error);
            });




        };
        // FilterShow.vue点击筛选按钮，会更新store.goSearchData，要同时更新SearchResults.vue的搜索结果列表
        watch(() => store.goSearchData, (newVal) => {
            // console.log('检测到变化：',newVal.data);
            currentPage.value = 1; //页码恢复默认第一页
            
            items.value = newVal.data.data;
            totalItems.value = newVal.data.total_item;
            // allItems.value = newVal.data.all_item;
        });
        onMounted(() => {
            fetchData();
            zhuanli_count();
        });
  </script>
  
  <style scoped>
    .search-results {
        padding: 20px;
        background-color: white;
        
    }
    
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    
    .content {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
         

        
    }
    
    .item-card {
        font-size: 14px;
        padding-left: 15px;
        padding-top: 15px;
        text-align: left;
        /* width: calc(100% - 20px); */
        background-color: #fff;
        border-radius: 5px;
        /* 添加阴影，这里选择较浅的阴影级别 */
        box-shadow: 0 0 20px rgba(82, 82, 82, 0.5); /* 外阴影 */
    }
    
    .footer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
    .xiangqing{
        color:00245a;
        text-decoration: none;
    }
    .xiangqing:hover {
      text-decoration: underline; /* 鼠标悬停时显示下划线 */
    }
     .el-checkbox__inner {
    border-color: black;
    background-color: white;
}
    .item-card div{
        margin: 5px 0;
    }

    .authorization{
        /* color: #0d0; */
        background-color: #009880;
        color: #fff;
        display: inline-block;
        height: 20px;
        font-size: 12px;
        border-radius: 4px;
        line-height: 20px;
        text-align: center;
        padding: 0 7px;
        white-space: nowrap;
    }

    .expiration{
        background-color: #e2e5e9;
        color: #1d2129;
        display: inline-block;
        height: 20px;
        font-size: 12px;
        border-radius: 4px;
        line-height: 20px;
        text-align: center;
        padding: 0 7px;
        white-space: nowrap;
    }

    .unpaid{
        color: orange;
        display: inline-block;
        height: 20px;
        font-size: 12px;
        border-radius: 4px;
        line-height: 20px;
        text-align: center;
        padding: 0 7px;
        white-space: nowrap;
    }

    .putong{
        color: black;
    }

    .country{
        display: inline-block;
        background:#f3e9af;;
        padding: 0 3px;
        color: #f79867;
        height: 20px;
        font-size: 12px;
        border-radius: 4px;
        line-height: 20px;
        text-align: center;
        padding: 0 7px;
        white-space: nowrap;
    }

    .shenqh{
        color: #994fbb;
    }

    .famingd{
        /* color: #689dd7; */
        background-color: rgb(242, 246, 255);
        padding: 3px 5px;
        color: #1c4cba;
        cursor: pointer;
        display: inline-block;
        border-radius: 5px;
    }

    .totalll{
        color: #ff0f12;
        font-weight: bolder;
        font-size: 20px;
        margin: 0 3px;
    }
    .floating-button {
        position: fixed;
        bottom: 80px; /* 距离底部的距离 */
        right: 22px;  /* 距离右侧的距离 */
        z-index: 1000; /* 确保按钮在页面最上层 */
        background-color: #f4f5f9;
        color: #00245a;
        border-radius: 50%; /* 可选择是否圆角 */
        width: 50px;       /* 设置宽高 */
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .floating-button:hover {
        background-color: #004080; /* 悬停颜色 */
        color:white;
    }
    
  </style>
  